import React, { useEffect, useState } from "react";
import { useTheme, TextField, Button, Box, MenuItem } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { axiosPrivate } from "../hooks/axios";
import { useLocation } from "react-router-dom";

const processData = (rawData) => {

    const sensorStats = {};
    rawData.forEach(({ sensor_type, value }) => {
    if (!sensorStats[sensor_type]) {
        sensorStats[sensor_type] = { min: value, max: value };
    } else {
        sensorStats[sensor_type].min = Math.min(sensorStats[sensor_type].min, value);
        sensorStats[sensor_type].max = Math.max(sensorStats[sensor_type].max, value);
    }
    });

    let normalLine = rawData.map(({ timestamp, value, sensor_type }) => {
        const { min, max } = sensorStats[sensor_type];
        const normalizedValue = (value - min) / (max - min);
        var dict = {
            timestamp : timestamp,
            sensor : sensor_type,
            [sensor_type] : normalizedValue,
            [sensor_type + "value"] : value
        }
        return dict;
      });
    
      var groupedData = normalLine.reduce((dict, { timestamp, sensor, ...rest }) => {
        if (!dict[timestamp]) {
          // If the timestamp is not present in the dictionary, create a new entry
          dict[timestamp] = { timestamp, [sensor]: rest[sensor], [sensor + "value"]: rest[sensor + "value"] };
        } else {
          // If the timestamp is already present, add the new sensor data without overwriting
          dict[timestamp] = {
            ...dict[timestamp],
            [sensor]: rest[sensor],
            [sensor + "value"]: rest[sensor + "value"]
          };
        }
        return dict;
      }, {});

      return Object.values(groupedData).sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      }, {});
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: "#fff", border: "1px solid #ccc", padding: "10px" }}>
          <p>{`Time: ${label}`}</p>
          {payload.map((item) => (
            <p key={item.dataKey} style={{ color: item.color }}>
              {`${item.dataKey}: ${item.payload[item.dataKey + "value"]}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

const SensorChart = () => {
    const location = useLocation();

    const [lineData, setLineData] = useState([]);
    const [sensorData, setSensorData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedSensors, setSelectedSensors] = useState(new Set());

    const [availableSensors, setAvailableSensors] = useState(new Set());

    useEffect(() => {
      var selectedSensors = new Set();
      var availableSensors = new Set();
      axiosPrivate.get("/sensor-data", { params: { module_code_name: location.pathname.slice(1, location.pathname.length )} }).then((res) => {
      //axiosPrivate.get("/sensor-data", { params: { module_code_name:"test"} }).then((res) => {
        var result = {};
        for (let sensorData of res.data) {
            selectedSensors.add(sensorData["sensor_type"]);
            availableSensors.add(sensorData["sensor_type"]);
          }
        
        result = processData(res.data);
        setSensorData(res.data);
        setSelectedSensors(selectedSensors);
        setAvailableSensors(availableSensors);
        setLineData(result);
      }).catch((error) => {
        console.log(error);
      });
    }, [location]);
  
    const handleFilter = () => {
       var tempLineData = [];
        for (let sensor_data of Array.from(sensorData)) {
          if (selectedSensors.has(sensor_data.sensor_type)) {
            var date = new Date(sensor_data.timestamp);
            date.setHours(0,0,0,0);
            let startDateObj = new Date(startDate);
            let endDateObj = new Date(endDate);
            startDateObj.setHours(0,0,0,0);
            endDateObj.setHours(0,0,0,0);
            if ((startDate ? date >= startDateObj : true) &&
                (endDate ? date <= endDateObj : true)) {
              tempLineData.push(sensor_data);
            }
        }
        setLineData(processData(tempLineData));
    }};


    return(
      <Box display={"flex"}>
        <Box flexGrow={1} >
        <ResponsiveContainer width= {600} height={400}>
            <LineChart data={lineData}>
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="timestamp" tick = {{angle : 0, textAnchor : "start"}} /> {/* TODO: modify angle if need */}
            <YAxis domain={[0, 'auto']}/>
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="temperature" label ="sensor" stroke="#FF0000" dot = {false} />
            <Line type="monotone" dataKey="conductivity" stroke="#00FF00" dot = {false} />
            <Line type="monotone" dataKey="humidity" stroke="#0000FF"  dot = {false} />
            <Line type="monotone" dataKey="nitrogen" stroke="#FFA500" dot = {false} />
            <Line type="monotone" dataKey="pH" stroke="#800080" dot = {false} />
            <Line type="monotone" dataKey="phosphorous" stroke="#00FFFF"dot = {false} />
            <Line type="monotone" dataKey="potassium" stroke="#808080"dot = {false} />
            <Line type="monotone" dataKey="light" stroke="#FFFF00" dot={false} />
            <Line type="monotone" dataKey="soilMoisture" stroke="#A52A2A" dot={false} />             
            {/*TODO add more type in here */}
            </LineChart>
        </ResponsiveContainer>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginRight: 2, marginLeft: 2 }}>
        <TextField
          type="date"
          label="Start Date"
          onChange={(e) => setStartDate(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          type="date"
          label="End Date"
          onChange={(e) => setEndDate(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          select
          label="Select Sensors"
          value={Array.from(selectedSensors)}
          onChange={(e) => setSelectedSensors((prev) => new Set(e.target.value))}
          SelectProps={{ multiple: true }}
          variant="outlined"
        >
          {Array.from(availableSensors).map((sensor) => (
            <MenuItem key={sensor} value={sensor}>
              {sensor}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" onClick={handleFilter}>Filter</Button>
      </Box>
    </Box>
    );
};

export default SensorChart;
