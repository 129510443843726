import axios from "axios";

const BASE_URL = "http://tridenth.com:8000";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
});

export const wsUrl = "ws://tridenth.com:8000/ws";
